@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.app {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: var(--aqua-grey);
}

.Router {
  width: 100%;
  height: 100%;
}

.Content {
  position: relative;
  width: 100vw;
  height: 100vh;
  padding-top: var(--header-height);
  background-color: var(--main-grey);
}

.StickySearchHeader {
  position: sticky;
  top: 0;
  background-color: var(--aqua-grey);
  z-index: 10;
}

input[type='color'] {
  border: none;
}

input[type='color']::-webkit-color-swatch {
  border: none;
}
